import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import Layout from "../components/layout"
import SEO from "../components/seo"

const PersonalTrainingPage = ({data}) => {
  const theme = useTheme();

  return (
  <Layout>
    <SEO title="Personal Training" />
    <Grid container direction="column" alignItems="center" style={{textAlign: 'center'}}>
      <Grid item style={{width: "100%", textAlign: 'center'}} xs={12}>
        <Typography variant="h3" style={{color: 'white', position: 'absolute', zIndex: '4', width: '100%'}}>Coming Soon</Typography>
        <StaticImage
            src="../images/coming_soon.jpg"
            quality={95}
            height={400}
            width={1600}
            placeholder={'TRACED_SVG'}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="A Gatsby astronaut"
            fadeIn={true}
            style={{height: '100%', width:"100%"}}
            layout="fullWidth"
        />
      </Grid>
    </Grid>
  </Layout>
)}

export default PersonalTrainingPage